import React from "react";

export const UploadIcon = () => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.4607 14.4694C28.605 13.8378 28.6786 13.1908 28.6796 12.5418C28.6694 10.1342 27.6831 7.84193 25.9613 6.22431C24.2395 4.60689 21.9409 3.81334 19.6265 4.03721C17.3121 4.26103 15.195 5.48191 13.7924 7.40175C13.3465 7.32213 12.8947 7.28417 12.4424 7.28839C8.61111 7.25069 5.43672 10.123 5.07184 13.7891C3.63128 14.2423 2.36527 15.1547 1.45457 16.3967C0.543867 17.6384 0.0348591 19.1464 0 20.7056C0 24.7118 3.32048 28 7.40707 28H24.5929C28.6795 28 32 24.7497 32 20.7056C31.9919 19.4354 31.6602 18.19 31.0387 17.0951C30.4174 16.0002 29.5281 15.0945 28.4607 14.4694ZM24.593 25.6945H17.113V16.019L20.3968 19.4204C20.677 19.7038 21.0822 19.8114 21.4594 19.7017C21.8366 19.5921 22.1286 19.2823 22.2255 18.8892C22.3225 18.4959 22.2095 18.0791 21.9293 17.7954L16.7847 12.4665C16.58 12.2585 16.3049 12.1423 16.0183 12.1423C15.7317 12.1423 15.4566 12.2585 15.2519 12.4665L10.0706 17.7954C9.797 18.0857 9.69341 18.5054 9.79929 18.8961C9.90517 19.2868 10.2042 19.5894 10.5837 19.6897C10.9635 19.7901 11.3659 19.6733 11.6397 19.3828L14.9235 15.9814V25.6946H7.44347C4.56104 25.6946 2.22581 23.4268 2.22581 20.6679C2.22581 17.909 3.97717 16.2459 6.34902 15.7545C6.59361 15.7029 6.81403 15.5658 6.97336 15.3665C7.13269 15.1672 7.22126 14.9172 7.22457 14.6584C7.22813 14.5956 7.22813 14.5323 7.22457 14.4696C7.26097 11.7106 9.59616 9.48055 12.4422 9.48055C12.9609 9.47791 13.4776 9.55437 13.975 9.70728C14.2051 9.77767 14.4512 9.76765 14.6754 9.6788C14.8997 9.58996 15.0893 9.42756 15.2155 9.21612C16.2814 7.42472 18.1387 6.3016 20.1685 6.22068C22.1985 6.13975 24.1336 7.11176 25.3291 8.813C26.5249 10.514 26.8235 12.7204 26.1256 14.6963C26.0294 14.9723 26.0403 15.276 26.1561 15.5439C26.2717 15.8118 26.4832 16.0227 26.7458 16.1324C27.6417 16.4912 28.4144 17.1173 28.9668 17.9314C29.5188 18.7455 29.8253 19.7109 29.8472 20.7056C29.8472 23.4645 27.4755 25.6945 24.593 25.6945Z"
      fill="currentColor"
    />
  </svg>
);
