import React from "react";

import { isNotNull } from "@/lib/magic-types";

import ErrorMessage from "../../molecules/ErrorMessage";
import { ApiErrorProps } from "./types";

/**
 * @deprecated use src/ui/molecules/SystemError.tsx
 */
export const ApiError = ({ errors: errorsProp }: ApiErrorProps) => {
  const errors = errorsProp.filter(isNotNull);

  if (errors.length === 0) {
    return null;
  }

  return (
    <>
      {errors.map((error) => (
        <ErrorMessage key={error.message} message={error.message} />
      ))}
    </>
  );
};
