import React, { FC } from "react";

import cn from "classnames";

import { ButtonIconProps } from "./types";
import { getHtmlTypeProp } from "./utils/getHtmlTypeProp";

import styles from "./styles.module.scss";

export const ButtonIcon: FC<ButtonIconProps> = ({
  htmlType = "button",
  size = "medium",
  variant = "danger",
  classNameButton,
  ...rest
}) => (
  <button
    {...rest}
    className={cn(
      styles.buttonIcon,
      {
        [styles.large]: size === "large",
        [styles.xLarge]: size === "x-large",
        [styles.medium]: size === "medium",
        [styles.small]: size === "small",
        [styles.danger]: variant === "danger",
        [styles.dangerOutline]: variant === "dangerOutline",
        [styles.lightYellow]: variant === "lightYellow",
        [styles.light]: variant === "light",
      },
      classNameButton
    )}
    type={getHtmlTypeProp(htmlType)}
  />
);
