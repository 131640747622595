import React from "react";

import { SystemErrorProps } from "./types";

import styles from "./styles.module.scss";

export const SystemError = ({ error, message }: SystemErrorProps) => {
  const errorMessage = message
    ? message
    : `Something bad happened, please reload ${error.toString()}`;

  return <div className={styles.root}>{errorMessage}</div>;
};
