import { preventDefaultDragEvent } from "./utils";

export const DEFAULT_ACCEPTED_TYPES = [
  "application/pdf",
  "image/jpeg",
  "image/png",
];

export const MAX_SIMULTANEOUS_UPLOADS = 2;

export const PREVENT_PLACEHOLDER_DRAG_EVENTS = {
  onDrag: preventDefaultDragEvent,
  onDragEnd: preventDefaultDragEvent,
  onDragEnter: preventDefaultDragEvent,
  onDragLeave: preventDefaultDragEvent,
  onDragOver: preventDefaultDragEvent,
  onDragStart: preventDefaultDragEvent,
};
