import React, { ComponentProps, FC } from "react";

type Props = ComponentProps<"svg">;

const ArrowLeft: FC<Props> = ({ ...rest }) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.21755 7.13234L6.21284 7.13698L5.63698 7.71284C5.55954 7.79028 5.51992 7.89121 5.51811 7.99269C5.5162 8.09905 5.55582 8.20601 5.63698 8.28716L6.21284 8.86302L6.21762 8.86773L10.231 12.8811C10.3895 13.0396 10.6466 13.0396 10.8052 12.8811L11.3811 12.3052C11.5396 12.1466 11.5396 11.8895 11.3811 11.731L7.65011 8L11.3811 4.26904C11.5396 4.11047 11.5396 3.85337 11.3811 3.69479L10.8052 3.11893C10.6466 2.96036 10.3895 2.96036 10.231 3.11893L6.21755 7.13234Z"
      fill="#1550A6"
    />
  </svg>
);

export default ArrowLeft;
