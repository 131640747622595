import { sentenceCase } from "change-case";
import { format } from "date-fns";
import React from "react";
import { UnreachableCaseError } from "ts-essentials";

import {
  Maintenance_Api_MaintenanceResolutionEnum,
  Property_Api_PropertyDetailsOutput,
  Property_Api_PropertyErrorEnum,
  Property_Api_PropertyLocationOutput,
  Property_Api_PropertyStatusEnum,
} from "../../graphql-schema-types.generated";

export const formatDateTime = (date: Date) =>
  format(date, "dd MMMM, yyyy HH:mm");

export const formatLocation = (
  location: Pick<
    Property_Api_PropertyLocationOutput,
    "flat" | "building" | "street" | "city" | "postcode"
  >,
  shortPostcode = false
) =>
  [
    location.flat,
    location.building,
    location.street,
    location.city,
    shortPostcode
      ? location.postcode?.split(" ")[0]
      : location.postcode?.replace(" ", "\u00A0"),
  ]
    .filter(Boolean)
    .join(", ");

export const formatRoomsConfiguration = (
  details: Pick<
    Property_Api_PropertyDetailsOutput,
    "bathrooms_no" | "bedrooms_no" | "kitchen_no" | "livingrooms_no"
  >,
  showLivingrooms = true,
  showKitchens = true
) => {
  const bedsCount = details.bedrooms_no ?? 0;
  const Beds =
    bedsCount > 0 ? (
      <span data-testid="property_beds">{`${bedsCount}\u00A0bedroom${
        bedsCount > 1 ? "s" : ""
      } · `}</span>
    ) : (
      <></>
    );
  const bathsCount = details.bathrooms_no ?? 0;
  const Baths =
    bathsCount > 0 ? (
      <span data-testid="property_baths">{`${
        details.bathrooms_no
      }\u00A0bathroom${bathsCount > 1 ? "s" : ""}`}</span>
    ) : (
      <></>
    );
  const kitchensCount = details.kitchen_no ?? 0;
  const Kitchens =
    kitchensCount > 0 && showKitchens ? (
      <span data-testid="property_kitchens">{` · ${kitchensCount}\u00A0kitchen${
        kitchensCount > 1 ? "s" : ""
      }`}</span>
    ) : (
      <></>
    );
  const livingroomsCount = details.livingrooms_no ?? 0;
  const Livingrooms =
    livingroomsCount > 0 && showLivingrooms ? (
      <span data-testid="property_livingrooms">{` · ${livingroomsCount}\u00A0livingroom${
        livingroomsCount > 1 ? "s" : ""
      }`}</span>
    ) : (
      <></>
    );

  return (
    <>
      {Beds}
      {Baths}
      {Kitchens}
      {Livingrooms}
    </>
  );
};

export function meterToMile(meters: number) {
  const oneMile = 1609.34;
  return Math.round((meters / oneMile) * 100) / 100;
}

export const formatAddress12 = (property: {
  location: Pick<Property_Api_PropertyLocationOutput, "address1" | "address2">;
}) =>
  [property.location.address1, property.location.address2]
    .map((a) => a?.trim())
    .filter((a) => a && a !== "")
    .join(", ");

export const formatPropertyStatusEnum = (
  status: Property_Api_PropertyStatusEnum
) =>
  //TODO implement proper statuses
  status[0] + status.slice(1).toLowerCase();

export const formatPropertyErrorEnum = (
  error: Property_Api_PropertyErrorEnum
) => {
  switch (error) {
    case Property_Api_PropertyErrorEnum.PaymentRequired:
      return "Payment required";
    case Property_Api_PropertyErrorEnum.InvalidRating:
      return "Invalid rating";
    case Property_Api_PropertyErrorEnum.PropertyNotExists:
      return "Property does not exist";
    case Property_Api_PropertyErrorEnum.UserNotExists:
      return "User does not exist";
    case Property_Api_PropertyErrorEnum.UnknownPropertyError:
      return "Unknown property error";
    case Property_Api_PropertyErrorEnum.InvalidMaxValue:
      return "Invalid max value";
    case Property_Api_PropertyErrorEnum.DocumentsVerificationError:
      return "Documents verification error";
    case Property_Api_PropertyErrorEnum.MaxLessMin:
      return "Maximum value is less than minimum";
    case Property_Api_PropertyErrorEnum.AlreadyPublished:
      return "Already published";
    case Property_Api_PropertyErrorEnum.DocumentRequired:
      return "Document required";
    case Property_Api_PropertyErrorEnum.UnknownValue:
      return "Unknown value";
    case Property_Api_PropertyErrorEnum.InvalidSum:
      return "Invalid sum";
    case Property_Api_PropertyErrorEnum.ZeroValue:
      return "Zero value";
    case Property_Api_PropertyErrorEnum.InvalidMinValue:
      return "Invalid min value";
    case Property_Api_PropertyErrorEnum.IntervalNotAvailable:
      return "Interval not available";
    case Property_Api_PropertyErrorEnum.EmptyValue:
      return "Empty value";
    case Property_Api_PropertyErrorEnum.PropertyLocked:
      return "Property is locked";
    case Property_Api_PropertyErrorEnum.NotReadyForPublication:
      return "Not ready for publication";
    case Property_Api_PropertyErrorEnum.PropertyRented:
      return "Property already rented";
    case Property_Api_PropertyErrorEnum.UserNotOwns:
      return "User not owns the property";
    case Property_Api_PropertyErrorEnum.PropertyNotDeleted:
      return "Property is not deleted";
    case Property_Api_PropertyErrorEnum.PropertyAlreadyDeleted:
      return "Property already deleted";
    default:
      throw new UnreachableCaseError(error);
  }
};

export const formatMaintenanceResolution = (
  resolution: Maintenance_Api_MaintenanceResolutionEnum
) => sentenceCase(resolution);

const nf = new Intl.NumberFormat("en", { minimumIntegerDigits: 1 });

export const formatFileSize = (n: number) => {
  if (n < 1024) {
    return n.toString();
  } else if (n < 1024 * 1024) {
    return `${nf.format(n / 1024)}Kb`;
  } else {
    return `${nf.format(n / (1024 * 1024))}Mb`;
  }
};

/**
 *  For usage with RFF to allow null values in the form. By default "empty" values will become strings "",
 * by using <Field allowNull format={formatAllowUndefined}/> you can store null there.
 */
export const formatAllowUndefined = (v: unknown) => v;
