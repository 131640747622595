import React from "react";

import { Property_Api_PropertyTypeEnum } from "@/graphql-schema-types.generated";

import {
  SearchPostCodeFragment,
  TownFragment,
} from "../graphql/fragments.generated";
import { LocationState } from "./update-search-from-page-params";

export const priceRangePcm = (from: number | undefined) => {
  const result: number[] = [];
  let step = 100;
  let i = from ?? step;
  while (i <= 6000) {
    result.push(i);
    if (i >= 1000) {
      step = 250;
    } else if (i >= 2000) {
      step = 500;
    } else if (i >= 3000) {
      step = 1000;
    }
    i += step;
  }
  return result;
};

export const bedroomsRange = (from: number | undefined) => {
  const result: number[] = [];
  for (let i = from ?? 1; i <= 10; i++) {
    result.push(i);
  }
  return result;
};

export const PROPERTY_TYPES: Property_Api_PropertyTypeEnum[] = [
  Property_Api_PropertyTypeEnum.PropertyTypeDetached,
  Property_Api_PropertyTypeEnum.PropertyTypeFlat,
  Property_Api_PropertyTypeEnum.PropertyTypeSemiDetached,
  Property_Api_PropertyTypeEnum.PropertyTypeStudio,
  Property_Api_PropertyTypeEnum.PropertyTypeTerraced,
];

export const toURI = (str: string) =>
  encodeURIComponent(str.toLowerCase().replace(/[\s/]/g, "-"));

const stripPostCode = (code: string) => code.split(" ")[0];

export type LocationStateSmall =
  | { type: "town"; town: Pick<TownFragment, "country" | "county" | "name"> }
  | {
      type: "postcode";
      postcode: Pick<SearchPostCodeFragment, "country" | "county" | "code">;
    };

export const makeSearchLinkAS = (
  location: LocationStateSmall | undefined,
  propertyType?: string | undefined,
  bedrooms?: number | undefined
) => {
  if (location == null) {
    return "/to-rent";
  }

  // const placePath = (
  //   location.type === "town"
  //     ? [location.town.country, location.town.county, location.town.name]
  //     : [
  //         location.postcode.country,
  //         location.postcode.county,
  //         stripPostCode(location.postcode.code),
  //       ]
  // )
  //   .map(toURI)
  //   .join("/");
  // return `/to-rent/${placePath}${
  //   propertyType != null
  //     ? `/${makePropertyType(false, propertyType, bedrooms)}`
  //     : ""
  // }`;
  return "/to-rent/";
};

export const makeSearchLinkHref = (
  location: LocationStateSmall,
  propertyType?: string | undefined,
  bedrooms?: number | undefined
) => {
  const placePath =
    location.type === "town"
      ? [location.town.country, location.town.county, location.town.name]
      : [
          location.postcode.country,
          location.postcode.county,
          stripPostCode(location.postcode.code),
        ].map(toURI);
  return {
    pathname: "/to-rent",
    query: {
      offset: 0,
      path:
        propertyType != null
          ? [...placePath, makePropertyType(false, propertyType, bedrooms)]
          : placePath,
    },
  };
};

export const makePageH1 = (
  location: LocationState | undefined,
  propertyType: Property_Api_PropertyTypeEnum | undefined,
  bedrooms: {
    max: number | undefined;
    min: number | undefined;
  }
) =>
  location != null ? (
    <>
      {bedrooms.min ? `${bedrooms.min} Bedroom ` : null}
      {makePropertyTypeForH1(propertyType)} to rent in{" "}
      <span>
        {location.type === "postcode"
          ? stripPostCode(location.postcode.code)
          : location.town.name}
        .
      </span>
    </>
  ) : (
    <>
      Only home feels <span>like home.</span>
    </>
  );

export const makePageTitle = (
  location: LocationState | undefined,
  propertyType: Property_Api_PropertyTypeEnum | undefined,
  bedrooms: number | undefined
) => {
  if (location == null) {
    return "Properties to rent | Houses and flats to rent | Mashroom";
  }
  const place = makePlaceName(location);
  if (propertyType == null) {
    return `Properties to rent in ${place} | Mashroom`;
  }
  return `${makePropertyTypeForTitle(
    propertyType,
    bedrooms
  )} to rent in ${place} | Mashroom`;
};

export const makeMetaDescription = (
  location: LocationState | undefined,
  propertyType: string | undefined,
  bedrooms: number | undefined
) => {
  if (location == null) {
    return "Search for all the latest properties available to rent today using the mashroom platform – combining technology and community to simplify the rental process.";
  }
  const place =
    location.type === "town"
      ? `${location.town.name} - ${location.town.county}`
      : `${stripPostCode(location.postcode.code)}- ${location.postcode.county}`;

  if (propertyType == null) {
    return `All the latest properties available to rent in ${place}. Search for your next rental property using mashroom’s convenient online lettings platform.`;
  }

  return `All the latest ${
    propertyType === "PROPERTY_TYPE_STUDIO"
      ? "studios"
      : makePropertyType(true, propertyType, bedrooms, true)
  } available to rent in ${place}. Search for your next rental property using mashroom’s convenient online lettings platform.`;
};

export const makePropertyTypeForH1 = (
  propertyType: Property_Api_PropertyTypeEnum | undefined
) => {
  switch (propertyType) {
    case Property_Api_PropertyTypeEnum.PropertyTypeStudio:
      return "Studios";
    case Property_Api_PropertyTypeEnum.PropertyTypeFlat:
      return "Flats";
    case Property_Api_PropertyTypeEnum.PropertyTypeDetached:
      return "Detached houses";
    case Property_Api_PropertyTypeEnum.PropertyTypeSemiDetached:
      return "Semi-detached houses";
    case Property_Api_PropertyTypeEnum.PropertyTypeTerraced:
      return "Terraced houses";
    default:
      return "Properties";
  }
};

export const makePropertyTypeForTitle = (
  propertyType: Property_Api_PropertyTypeEnum | undefined,
  bedrooms: number | undefined
) => {
  switch (propertyType) {
    case Property_Api_PropertyTypeEnum.PropertyTypeStudio:
      return "Studios";
    case Property_Api_PropertyTypeEnum.PropertyTypeFlat:
      if (bedrooms != null) {
        return [bedrooms, "bedroom", `flats`].join(" ");
      } else {
        return "Flats";
      }
    case Property_Api_PropertyTypeEnum.PropertyTypeDetached:
      if (bedrooms != null) {
        return [bedrooms, "bedroom", `detached houses`].join(" ");
      } else {
        return "Detached houses";
      }
    case Property_Api_PropertyTypeEnum.PropertyTypeSemiDetached:
      if (bedrooms != null) {
        return [bedrooms, "bedroom", `semi-detached houses`].join(" ");
      } else {
        return "Semi-detached houses";
      }
    case Property_Api_PropertyTypeEnum.PropertyTypeTerraced:
      if (bedrooms != null) {
        return [bedrooms, "bedroom", `terraced houses`].join(" ");
      } else {
        return "Terraced houses";
      }
    default:
      if (bedrooms != null) {
        return [bedrooms, "bedroom", `properties`].join(" ");
      } else {
        return "Properties";
      }
  }
};

export const makePropertyType = (
  alwaysPlural: boolean,
  propertyType: string | string[] | undefined,
  bedrooms: number | undefined,
  withoutDashes = false,
  capitalized = false
) => {
  const housesTypes = new Set([
    "PROPERTY_TYPE_SEMI_DETACHED",
    "PROPERTY_TYPE_DETACHED",
    "PROPERTY_TYPE_TERRACED",
  ]);

  if (propertyType == null) {
    return "";
  }
  const isHouse = Array.isArray(propertyType)
    ? propertyType.some((t) => housesTypes.has(t))
    : housesTypes.has(propertyType);

  const isFlat = propertyType === "PROPERTY_TYPE_FLAT";
  const isStudio = propertyType === "PROPERTY_TYPE_STUDIO";

  if (isStudio) {
    return capitalized ? "Studios" : "studios";
  } else if (isFlat) {
    if (bedrooms != null) {
      return [bedrooms, "bedroom", `flat${alwaysPlural ? "s" : ""}`].join(
        withoutDashes ? " " : "-"
      );
    } else {
      return capitalized ? "Flats" : "flats";
    }
  } else if (isHouse) {
    if (bedrooms != null) {
      return [bedrooms, "bedroom", `house${alwaysPlural ? "s" : ""}`].join(
        withoutDashes ? " " : "-"
      );
    } else {
      return capitalized ? "Houses" : "houses";
    }
  } else {
    return capitalized ? "Properties" : "properties";
  }
};
const propertyTypeRegex = /^(\d)-bedroom-(flat|house)$/;

export const isPropertyType = (value: string): boolean => {
  if (propertyTypeRegex.test(value)) {
    return true;
  }

  switch (value) {
    case "studio":
    case "studios":
    case "flats":
    case "houses":
    case "private-landlord":
    case "properties":
    case "rooms":
      return true;
    default:
      return false;
  }
};
export const parsePropertyType = (
  value: string
): {
  bedrooms?: number;
  propertyType?:
    | Property_Api_PropertyTypeEnum
    | Property_Api_PropertyTypeEnum[];
} => {
  switch (value) {
    case "studio":
    case "studios": {
      return { propertyType: Property_Api_PropertyTypeEnum.PropertyTypeStudio };
    }
    case "flats": {
      return { propertyType: Property_Api_PropertyTypeEnum.PropertyTypeFlat };
    }
    case "houses": {
      return {
        propertyType: [
          Property_Api_PropertyTypeEnum.PropertyTypeSemiDetached,
          Property_Api_PropertyTypeEnum.PropertyTypeDetached,
          Property_Api_PropertyTypeEnum.PropertyTypeTerraced,
        ],
      };
    }
    case "private-landlord": {
      return {};
    }
    case "properties": {
      return {};
    }
    case "rooms": {
      return {};
    }
    // No default
  }
  const match = propertyTypeRegex.exec(value);
  if (match) {
    return {
      propertyType:
        match[2] === "flat"
          ? Property_Api_PropertyTypeEnum.PropertyTypeFlat
          : [
              Property_Api_PropertyTypeEnum.PropertyTypeSemiDetached,
              Property_Api_PropertyTypeEnum.PropertyTypeDetached,
              Property_Api_PropertyTypeEnum.PropertyTypeTerraced,
            ],
      bedrooms: Number(match[1]),
    };
  } else {
    throw new Error(`Wrong property type: ${value}`);
  }
};

const POSTCODE_REGEXP =
  /^((?:(?:gir)|[a-pr-uwyz](?:(?:\d(?:[a-hjkps-uw]|\d)?)|(?:[a-hk-y]\d(?:\d|[abehmnprv-y])?))))$/;
export const parsePostcode = (value: string) => {
  const match = POSTCODE_REGEXP.exec(value);
  if (match) {
    return match[1];
  } else {
    return undefined;
  }
};
export const parseSearchQueryPath = (path: string[]) => {
  let country: string | undefined;
  let county: string | undefined;
  let town: string | undefined;
  let propertyType:
    | Property_Api_PropertyTypeEnum
    | Property_Api_PropertyTypeEnum[]
    | undefined;
  let bedrooms: number | undefined;
  let postcode: string | undefined;

  if (path.length === 0) {
    return {
      country: undefined,
      county: undefined,
      town: undefined,
      propertyType: undefined,
      bedrooms: undefined,
      postcode: undefined,
    };
  }

  // "/to-rent/england/bedfordshire/ampthill/5 bedroom flat"
  // "/to-rent/england/bedfordshire/ampthill"
  // "/to-rent/england/bedfordshire"
  // "/to-rent/england/bedfordshire/b8"
  // "/to-rent/england/london/br1"
  // "/to-rent/england/br1"
  // "/to-rent/br1"

  const parseEnding = (str: string) => {
    const p = parsePostcode(str);

    if (p) {
      postcode = p;
      return true;
    } else if (isPropertyType(str)) {
      const res = parsePropertyType(str);
      propertyType = res.propertyType;
      bedrooms = res.bedrooms;
      return true;
    }
    return false;
  };

  for (const [i, element] of path.entries()) {
    const success = parseEnding(element);
    if (!success) {
      switch (i) {
        case 0:
          country = element;
          break;
        case 1:
          county = element;
          break;
        case 2:
          town = element;
          break;
      }
    }
  }
  // Special case of "/to-rent/england/london/br1"
  if (town == null && county != null && postcode != null) {
    town = county;
    county = undefined;
  }

  return {
    country,
    county,
    town,
    propertyType,
    bedrooms,
    postcode,
  };
};

export const hackChoosePropertyTypeHouse = (
  propertyType: Property_Api_PropertyTypeEnum | Property_Api_PropertyTypeEnum[]
): Property_Api_PropertyTypeEnum =>
  Array.isArray(propertyType)
    ? Property_Api_PropertyTypeEnum.PropertyTypeDetached
    : propertyType;

function makePlaceName(location: LocationState) {
  return location.type === "town"
    ? location.town.name
    : stripPostCode(location.postcode.code);
}
