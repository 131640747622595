import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";

import { Form } from "react-final-form";
import scaleCropRotate, {
  imageDataToBlob,
  imageDataToDataURL,
  URLToImageData,
} from "scale-crop-rotate";
import { v4 } from "uuid";

import { Modal } from "@/components/ui";
import { getMediaUrl } from "@/lib/utils";
import { Icon } from "@/ui/components/Icon";
import Button from "@/uikit2/atoms/Button";
import { FileElementNew, FileElementType } from "@/uikit2/types";

// We'll replace these icon components with svgs
import ArrowLeft from "./icons/ArrowLeft";
import ArrowRight from "./icons/ArrowRight";
import Rotate from "./icons/Rotate";
import { ImageEditorNewFormValues, ImageEditorNewProps } from "./types";

import styles from "./styles.module.scss";

export const ImageEditorNew: FC<ImageEditorNewProps> = ({
  imageIndex,
  value,
  onChange,
  onNext,
  onPrev,
  onDelete,
  onCancel,
}) => {
  const [imageData, setImageData] = useState<ImageData | null>(null);
  const fileElement = value[imageIndex];
  const imageId = fileElement.fileId;

  const [imageDataUrl, setImageDataUrl] = useState<string | null>(
    getMediaUrl(imageId)
  );

  const needSave = imageDataUrl?.startsWith("data") ?? false;

  const noPreview = fileElement.name.includes(".pdf");

  useEffect(() => {
    const loadImageData = async () => {
      if (imageId != null) {
        const imageData = await URLToImageData(getMediaUrl(imageId));
        setImageData(imageData);
        const url = imageDataToDataURL(imageData);
        setImageDataUrl(url);
      } else {
        setImageData(null);
        setImageDataUrl(null);
      }
    };
    void loadImageData();
  }, [imageId]);

  async function handleClickRotate(event: SyntheticEvent<HTMLSpanElement>) {
    event.preventDefault();

    const data = await scaleCropRotate(imageData, "90deg", true);
    setImageData(data);
    const dataUrl = imageDataToDataURL(data);
    setImageDataUrl(dataUrl);
  }

  const handleSaveForm = React.useCallback(
    async (values: ImageEditorNewFormValues) => {
      const newValue = [...value];

      if (imageData != null) {
        const blob = await imageDataToBlob(imageData);

        newValue[imageIndex] = {
          ...value[imageIndex],
          type: FileElementType.NEW,
          file: blob,
          uuid: v4(),
          id: undefined,
          description: values.description,
        } as FileElementNew;
      }
      if (fileElement.description !== values.description) {
        newValue[imageIndex].description = values.description;
      }
      onChange(newValue);
    },
    [value, imageData, imageIndex, onChange, fileElement.description]
  );

  const initialValues = useMemo(
    () => ({
      description: fileElement.description,
    }),
    [fileElement.description]
  );

  return (
    <Modal
      dialogClassName={styles.dialog}
      onClose={onCancel}
      title={`Edit photo ${imageIndex + 1} of ${value.length}`}
    >
      <div className={styles.content}>
        <Form<ImageEditorNewFormValues>
          key={fileElement.id ?? fileElement.uuid}
          initialValues={initialValues}
          onSubmit={handleSaveForm}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.currentImageWrap}>
                {noPreview ? (
                  <Icon className={styles.pdfIcon} name="pdfFile" />
                ) : (
                  <img
                    alt=""
                    className={styles.currentImage}
                    src={imageDataUrl ?? ""}
                  />
                )}
              </div>

              <div className={styles.imageAction}>
                {value.length > 1 && (
                  <button
                    className={styles.imageActionButton}
                    onClick={onPrev}
                    type="button"
                  >
                    <ArrowLeft />
                  </button>
                )}
                {!noPreview && (
                  <button
                    className={styles.imageActionButton}
                    onClick={handleClickRotate}
                    type="button"
                  >
                    <Rotate />
                    <span>Rotate 90°</span>
                  </button>
                )}
                {value.length > 1 && (
                  <button
                    className={styles.imageActionButton}
                    onClick={onNext}
                    type="button"
                  >
                    <ArrowRight />
                  </button>
                )}
              </div>

              {/* <Label>Description</Label>
              <Field
                name="description"
                render={({ input }) => <TextInput {...input} />}
              /> */}

              <div className={styles.actions}>
                <Button.Red
                  className={styles.deleteButton}
                  onClick={() => onDelete(imageIndex)}
                  type="button"
                >
                  Delete photo
                </Button.Red>
                <Button.Blue disabled={!needSave || submitting} type="submit">
                  Save
                </Button.Blue>
              </div>
            </form>
          )}
        </Form>
      </div>
    </Modal>
  );
};
