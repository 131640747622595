import React, { FC, useMemo } from "react";

import Link from "next/link";

import { UrlObject } from "url";

import { ListingLinkProps } from "./types";

export const ListingLink: FC<ListingLinkProps> = ({
  propertyId,
  additionalServices,
  activity,
  ...rest
}) => {
  const pathname = activity
    ? "/listing/[propertyId]/[activity]"
    : "/listing/[propertyId]";

  const href: UrlObject = useMemo(
    () => ({
      pathname,
      query: {
        propertyId,
        activity,
        ...(additionalServices ? { addServices: additionalServices } : {}),
      },
    }),
    [activity, additionalServices, pathname, propertyId]
  );

  return <Link href={href} passHref {...rest} />;
};
