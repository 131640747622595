import React, { RefObject, useCallback, useMemo, useState } from "react";

import Image from "next/image";

import useClickOutside from "click-outside-hook";
import { dropLast, last } from "ramda";
import { Field, useForm } from "react-final-form";

import { required } from "@/lib/validations";
import { FileElementType } from "@/uikit2/types";

import { formatImageSrc, parseSpecialCharacters } from "../../utils";
import { DOCUMENT_IMAGE_SIZE, IMAGE_EXTENSIONS } from "./constants";
import { CloseIcon } from "./icons/CloseIcon";
import { DoneIcon } from "./icons/DoneIcon";
import { EditIcon } from "./icons/EditIcon";
import { ImagePreviewProps } from "./types";

import styles from "./styles.module.scss";

import documentImage from "./assets/document.png";

export const ImagePreview = ({
  file,
  editable,
  onClick,
  onDelete,
  onEdit,
}: ImagePreviewProps) => {
  const [editName, setEditName] = useState(false);
  const noPreview = useMemo(
    () => !IMAGE_EXTENSIONS.has(last(file.name.split(".")) ?? ""),
    [file.name]
  );
  const ref = useClickOutside(() =>
    setEditName(false)
  ) as RefObject<HTMLDivElement>;

  const inputName = `${file.id ?? file.uuid}.name`;
  const form = useForm();
  const fieldState = form.getFieldState(inputName);

  const handleDelete = useCallback(() => onDelete(file), [file, onDelete]);

  const handleStartEdit = useCallback(() => {
    setEditName(true);
    form.change(inputName, dropLast(1, file.name.split(".")).join("."));
  }, [file.name, form, inputName]);

  const handleCompleteEdit = useCallback(
    (newName: string | undefined) => () => {
      if (newName) {
        onEdit(`${newName}.${last(file.name.split(".")) ?? ""}`);
      }
      setEditName(false);
    },
    [onEdit, file.name]
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          {file.type === FileElementType.UPLOADING ? (
            <div className={styles.loadingContainer}>
              <div className={styles.loading} />
            </div>
          ) : (
            <div
              className={styles.image}
              onClick={onClick}
              style={
                !noPreview
                  ? { backgroundImage: `url(${formatImageSrc(file)})` }
                  : {}
              }
            >
              {noPreview && (
                <Image
                  height={DOCUMENT_IMAGE_SIZE}
                  src={documentImage}
                  width={DOCUMENT_IMAGE_SIZE}
                />
              )}
            </div>
          )}
          {editName ? (
            <div ref={ref} className={styles.editContainer}>
              <div className={styles.label}>Document name</div>
              <Field
                name={inputName}
                parse={parseSpecialCharacters}
                validate={required()}
              >
                {({ input }) => (
                  <div className={styles.textInputContainer}>
                    <input className={styles.textInput} {...input} />
                    <button
                      className={styles.editComplete}
                      disabled={fieldState?.invalid}
                      onClick={handleCompleteEdit(
                        fieldState?.value as string | undefined
                      )}
                      type="button"
                    >
                      <DoneIcon />
                    </button>
                  </div>
                )}
              </Field>
            </div>
          ) : (
            <>
              <div
                className={styles.fileName}
                onClick={onClick}
                title={file.name}
              >
                {file.name}
              </div>
              {editable && file.type !== FileElementType.UPLOADING && (
                <button
                  className={styles.edit}
                  onClick={handleStartEdit}
                  type="button"
                >
                  <EditIcon />
                </button>
              )}
            </>
          )}
        </div>
        <button className={styles.remove} onClick={handleDelete} type="button">
          <CloseIcon />
        </button>
      </div>
      {file.type === FileElementType.UPLOAD_ERROR && <div>{file.error}</div>}
    </>
  );
};
