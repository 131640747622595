import React from "react";

import Loading from "@/uikit2/atoms/Loading";

import styles from "./styles.module.scss";

export const FullContentSpinner = () => (
  <div className={styles.root}>
    <Loading />
  </div>
);
