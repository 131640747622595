import React, { FC } from "react";

import { Skeleton } from "../../../../uikit2/atoms/Skeleton";

import css from "./index.module.css";

export const PhoneVerificationSkeleton: FC = () => (
  <div className={css.container}>
    <Skeleton className={css.text1} />
    <Skeleton className={css.text2} />
    <Skeleton className={css.text3} />
  </div>
);
