import React, { ComponentProps, FC } from "react";

type Props = ComponentProps<"svg">;

const Rotate: FC<Props> = ({ ...rest }) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 8C13.5 5.23858 11.2614 3 8.5 3C5.73858 3 3.5 5.23858 3.5 8C3.5 10.7614 5.73858 13 8.5 13M13.5 8L15 5.5M13.5 8L11 7"
      stroke="#1550A6"
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>
);

export default Rotate;
