import React, { FC } from "react";

import { CSSTransition } from "react-transition-group";

import { Portal } from "@/components/Portal";
import { CSS_TRANSITION_TIMEOUT } from "./constants";
import { ErrorMessageProps } from "./types";

import s from "./index.module.css";

export const ErrorMessage: FC<ErrorMessageProps> = ({
  message,
  children,
  onClose,
}) => (
  <Portal>
    <CSSTransition
      unmountOnExit
      classNames="transition-error-message"
      in={Boolean(message)}
      timeout={CSS_TRANSITION_TIMEOUT}
    >
      <div className="transition-error-message">
        <div className={s.stick}>
          <div className={s.stickMessage}>{message}</div>
          {onClose && (
            <button className={s.stickAction} onClick={onClose} type="button">
              {children}
            </button>
          )}
        </div>
      </div>
    </CSSTransition>
  </Portal>
);

export default ErrorMessage;
