import { HtmlType } from "../constants";

export const getHtmlTypeProp = (type: HtmlType): HtmlType => {
  switch (type) {
    case "submit":
    case "reset":
    default:
      return "button";
  }
};
