import React, { FC, useCallback, useRef, useState } from "react";

import { Semaphore } from "await-semaphore";
import axios from "axios";
import { v4 } from "uuid";

import { HtmlAndTouchDndProvider } from "@/components/HtmlAndTouchDndProvider";
import { MEDIA_PATH } from "@/constants";
import { ImageEditorNew } from "@/entities/ImageEditorNew";
import { formatFileSize } from "@/lib/formatters/misc";
import { isNotNull } from "@/lib/magic-types";
import { getMediaUrl, MAX_FILE_SIZE } from "@/lib/utils";
import {
  FileElement,
  FileElementJustUploaded,
  FileElementNew,
  FileElementType,
  FileElementUploadError,
  FileElementUploading,
} from "@/uikit2/types";

import { FileInput, Placeholder } from "./components";
import {
  DEFAULT_ACCEPTED_TYPES,
  MAX_SIMULTANEOUS_UPLOADS,
  PREVENT_PLACEHOLDER_DRAG_EVENTS,
} from "./constants";
import { ImageUploadProps } from "./types";
import { extractFileKey } from "./utils";

import styles from "./styles.module.scss";

const semaphore = new Semaphore(MAX_SIMULTANEOUS_UPLOADS);

export const ImageUpload: FC<ImageUploadProps> = ({
  acceptedTypes = DEFAULT_ACCEPTED_TYPES,
  input,
  placeholder,
  multiple = false,
  readonly = false,
  maxSizeFile = MAX_FILE_SIZE,
  removeText = "remove",
  noPreview = false,
  editable = false,
  optional = false,
  ...props
}) => {
  const [imageToEditIndex, setImageToEdit] = useState<number | null>(null);

  /*
      I'm using ref here because we have a lot of callbacks that
    are being called after and during file upload and they all
    get stale input value resulting in resetting values to
    initial state. So we just storing most current value in ref
    and passing ref to all callbacks.
  */
  const inputValue = useRef<FileElement[]>([]);
  inputValue.current = Array.isArray(input.value) ? input.value : [];

  const handleUploadFinished = useCallback(
    (fileElement: FileElementUploading, id: string) => {
      input.onBlur();
      input.onChange(
        inputValue.current.map((d): FileElement => {
          // Replacing only specific file that just been uploaded
          if (d.uuid !== fileElement.uuid) {
            return d;
          }
          return {
            ...d,
            type: FileElementType.JUST_UPLOADED,
            uuid: d.uuid,
            id: undefined,
            fileId: id,
          } as FileElementJustUploaded;
        })
      );
    },
    [input]
  );

  /**
   * File Upload Error.
   */
  const handleUploadError = useCallback(
    (value: FileElementNew | FileElementUploading, error: string) => {
      input.onChange(
        inputValue.current.map((d) => {
          if (d.type !== FileElementType.UPLOADING) {
            return d;
          }
          if (d.file !== value.file) {
            return d;
          }

          return { ...d, type: FileElementType.UPLOAD_ERROR, error };
        })
      );
    },
    [input]
  );

  const handleProgressChange = useCallback(
    (value: FileElementNew | FileElementUploading, progress: number) => {
      input.onChange(
        inputValue.current.map((d) => {
          if (d.type !== FileElementType.UPLOADING) {
            return d;
          }
          if (d.file !== value.file) {
            return d;
          }

          return { ...d, progress };
        })
      );
    },
    [input]
  );

  const validateFiles = useCallback(
    (
      fileElement: FileElementUploading
    ): FileElementUploadError | FileElementUploading => {
      if (!acceptedTypes.includes(fileElement.file.type)) {
        return {
          ...fileElement,
          type: FileElementType.UPLOAD_ERROR,
          error: `Wrong file type. Possible file types for upload: ${acceptedTypes.join(
            ", "
          )}`,
        };
      }

      if (fileElement.file.size > maxSizeFile) {
        return {
          ...fileElement,
          type: FileElementType.UPLOAD_ERROR,
          error: `File is too big. Maximum size allowed is: ${formatFileSize(
            maxSizeFile
          )}`,
        };
      }
      return fileElement;
    },
    [acceptedTypes, maxSizeFile]
  );

  const startUpload = useCallback(
    (fileElement: FileElementUploading) => {
      const formData = new FormData();
      formData.append("file", fileElement.file);
      if (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        fileElement.description != null &&
        fileElement.description.trim() !== ""
      ) {
        formData.append("description", fileElement.description.trim());
      }
      void semaphore.use(() =>
        axios
          .post(MEDIA_PATH, formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent: ProgressEvent) => {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if (progressEvent.total != null) {
                handleProgressChange(
                  fileElement,
                  progressEvent.loaded / progressEvent.total
                );
              }
            },
          })
          .then((r) => {
            handleUploadFinished(fileElement, r.data.id as string);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            handleUploadError(fileElement, `Upload error: ${error.toString()}`);
          })
      );
    },
    [handleProgressChange, handleUploadError, handleUploadFinished]
  );

  const processFiles = useCallback(
    (rawFiles: FileList | null) => {
      const files: FileElement[] = [];

      if (rawFiles != null) {
        for (const rawFile of rawFiles) {
          const newFile: FileElementUploading = {
            type: FileElementType.UPLOADING,
            id: undefined,
            uuid: v4(),
            fileId: undefined,
            progress: 0,
            file: rawFile,
            name: rawFile.name,
            size: rawFile.size,
            description: "",
          };
          const file = validateFiles(newFile);
          files.push(file);
          if (file.type !== FileElementType.UPLOAD_ERROR) {
            startUpload(file);
          }
        }
      }
      return files;
    },
    [startUpload, validateFiles]
  );

  const handleDrop = useCallback(
    (ev: React.DragEvent<HTMLLabelElement>) => {
      ev.preventDefault();
      input.onBlur();
      input.onChange(
        inputValue.current.concat(processFiles(ev.dataTransfer.files))
      );
    },
    [input, processFiles]
  );

  const handleSelectFile = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      ev.preventDefault();
      input.onBlur();
      input.onChange(inputValue.current.concat(processFiles(ev.target.files)));
    },
    [input, processFiles]
  );

  function handleClickImage(idx: number) {
    setImageToEdit(idx);
  }
  function handleCancelEdit() {
    setImageToEdit(null);
  }
  function handleClickPrev() {
    setImageToEdit((idx) =>
      idx != null ? (idx === 0 ? inputValue.current.length - 1 : idx - 1) : null
    );
  }

  const handleClickForward = useCallback(() => {
    setImageToEdit((idx) =>
      idx != null ? (idx === inputValue.current.length - 1 ? 0 : idx + 1) : null
    );
  }, []);

  const handleDeleteImage = useCallback(
    (index: number) => {
      const newValue = inputValue.current
        .map((d, idx) => {
          if (idx !== index) {
            return d;
          }

          /* Deleting unsaved newly added or just uploaded but not saved yet element.
             Just ignore it and return undefined and we will filter it afterwards
          */
          if (
            d.type === FileElementType.UPLOADING ||
            d.type === FileElementType.UPLOAD_ERROR ||
            d.type === FileElementType.JUST_UPLOADED
          ) {
            return undefined;
          }
          if (d.type === FileElementType.OLD) {
            return { ...d, type: FileElementType.DELETED_OLD };
          }
          return d;
        })
        .filter(isNotNull);

      input.onChange(newValue);

      setImageToEdit(null);
    },
    [input]
  );

  const handleSave = useCallback(
    (newValues: FileElement[]) => {
      const uploadDescriptionChanges = (values: FileElement) => {
        const formData = new FormData();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (values.description != null) {
          formData.append("description", values.description);
        }

        return axios({
          method: "patch",
          url: getMediaUrl(values.id),
          data: formData,
          headers: {
            "content-type": "multipart/form-data",
          },
        });
      };

      for (const [idx, v] of newValues.entries()) {
        if (v.type === FileElementType.NEW) {
          startUpload({ ...v, type: FileElementType.UPLOADING, progress: 0 });
        } else if (v.description !== inputValue.current[idx].description) {
          void semaphore.use(() => uploadDescriptionChanges(v));
        }
      }
      setImageToEdit(null);
      input.onBlur();
      input.onChange(newValues);
    },
    [startUpload, input]
  );

  const handleEditName = useCallback(
    (idx: number, value: FileElement, newName: string) => {
      const newValues = [...inputValue.current];
      if (newName !== inputValue.current[idx].name) {
        newValues[idx] = {
          ...value,
          name: newName,
        };

        const formData = new FormData();
        formData.append("name", newName);

        void semaphore.use(() =>
          axios({
            method: "patch",
            url: getMediaUrl(value.fileId),
            data: formData,
            headers: {
              "content-type": "multipart/form-data",
            },
          })
        );
      }
      input.onBlur();
      input.onChange(newValues);
    },
    [input]
  );

  const currentFileElements = inputValue.current.filter(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (e) => e.type && e.type !== FileElementType.DELETED_OLD
  );

  return (
    <HtmlAndTouchDndProvider>
      {!readonly && (multiple || currentFileElements.length === 0) && (
        <Placeholder
          {...PREVENT_PLACEHOLDER_DRAG_EVENTS}
          multiple={multiple}
          onDrop={handleDrop}
          optional={optional}
          placeholder={placeholder}
        >
          <input
            accept={acceptedTypes.join(",")}
            className={styles.input}
            data-testid={props["data-testid"]}
            multiple={multiple}
            name={input.name}
            onChange={handleSelectFile}
            type="file"
          />
        </Placeholder>
      )}

      {currentFileElements.length > 0 && (
        <div>
          {currentFileElements.map((file, idx) => (
            <FileInput
              key={extractFileKey(file)}
              editable={editable}
              fileElement={file}
              index={idx}
              onClick={handleClickImage}
              onDelete={handleDeleteImage}
              onEdit={handleEditName}
            />
          ))}
        </div>
      )}

      {!noPreview && imageToEditIndex != null && (
        <ImageEditorNew
          imageIndex={imageToEditIndex}
          onCancel={handleCancelEdit}
          onChange={handleSave}
          onDelete={handleDeleteImage}
          onNext={handleClickForward}
          onPrev={handleClickPrev}
          value={inputValue.current}
        />
      )}
    </HtmlAndTouchDndProvider>
  );
};
