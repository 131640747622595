import React from "react";

export const EditIcon = () => (
  <svg
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.89829 3.75015C10.6793 2.9691 11.9457 2.9691 12.7267 3.75015L12.7565 3.77997C13.5376 4.56102 13.5376 5.82735 12.7565 6.6084L6.34939 13.0155C6.23017 13.1348 6.08275 13.2219 5.92082 13.2689L2.90358 14.1439C2.51879 14.2555 2.1656 13.8918 2.28838 13.5104L3.2354 10.5689C3.28404 10.4178 3.36795 10.2805 3.48018 10.1683L9.89829 3.75015Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <line
      stroke="currentColor"
      strokeWidth="1.5"
      x1="8.18658"
      x2="11.296"
      y1="5.18842"
      y2="8.2978"
    />
  </svg>
);
