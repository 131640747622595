import {
  differenceInYears,
  isAfter,
  isBefore,
  isToday,
  parseISO,
} from "date-fns";

import { FileElement, FileElementType } from "@/uikit2/types";

import { isValidDate } from "./is-valid-date";

const isFileElementArray = (
  v: unknown | null | undefined
): v is FileElement[] => Array.isArray(v) && typeof v[0]?.type === "string";

export const filesWereUploaded =
  (message: string) => (values: unknown | null | undefined) =>
    isFileElementArray(values) &&
    values.some((f) => f.type === FileElementType.UPLOADING)
      ? message
      : undefined;

export const nonEmptyArray =
  (message = "Required") =>
  (value: unknown[] | null | undefined) =>
    (value?.length ?? 0) > 0 ? undefined : message;

export const uniqEmail =
  (emails: string[], message = "Enter a unique email") =>
  (email: string) =>
    emails.includes(email) ? message : undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isString = (func: (v: string) => string | undefined) => (v: any) =>
  typeof v === "string" ? func(v) : undefined;

export const timeValidator =
  (message = "Incorrect time") =>
  (time: string) => {
    const match = /^(([,01]\d)|(2[0-3])|(\d)):([0-5]\d)$/.exec(time);
    return (match?.length ?? 0) > 0 ? undefined : message;
  };

export const completePhone = (value: unknown | null | undefined) => {
  if (typeof value === "string" && value.length !== 12) {
    return "Fill the phone number";
  }
  return undefined;
};

// > 1900 && 18yrs
export const birthday = () =>
  withEmpty((value: unknown) => {
    const date =
      typeof value === "string"
        ? new Date(value)
        : (value instanceof Date
        ? value
        : undefined);

    if (!isValidDate(date)) {
      return "Date is not valid";
    }

    if (date != null) {
      const now = Date.now();
      if (differenceInYears(now, date) < 18) {
        return "You should be at least 18 years old";
      }
      if (isBefore(date, parseISO("1900-01-01 00:00"))) {
        return "Date is too far in the past";
      }
    }
    return undefined;
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withEmpty =
  (f: any) =>
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    // eslint-disable-next-line @typescript-eslint/ban-types
    values: object,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: any
  ) => {
    if (!isEmpty(value)) {
      return f(value, values, props);
    } else {
      return undefined;
    }
  };

export const isEmpty = <T>(
  value: T | null | undefined
): value is null | undefined =>
  value == null || (typeof value === "string" && value.trim().length === 0);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isYoutubeLink = (value: any) => {
  const re =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

  if (!value || !value?.[0] || value?.[0]?.match(re)) {
    return undefined;
  }

  return "Is not a valid youtube link";
};

export const isDateLessThenToday = (message?: string) => (value: unknown) => {
  if (typeof value === "string") {
    const newD = new Date(value);

    return isValidDate(value) &&
      (isToday(newD) || (!isToday(newD) && isAfter(newD, new Date())))
      ? undefined
      : message ?? "The date cannot be in the past";
  }

  return undefined;
};

export const enumRequired = (msg: string) => (v: unknown) =>
  withEmpty((v) =>
    typeof v === "string" && !v.toLowerCase().includes("unknown")
      ? undefined
      : msg
  );
