import React, { FC, useCallback, useEffect, useState } from "react";

import cn from "classnames";

import { InputTextWithLabelProps } from "./types";

import styles from "./styles.module.scss";

// TODO: It must be merged into one TextField component
// TODO: Replace all InputTextWithLabel with TextField
export const InputTextWithLabel: FC<InputTextWithLabelProps> = ({
  label,
  fieldProps,
  type = "text",
  icon,
  disabled = false,
  classNameInput,
  classNameIcon,
  ...rest
}) => {
  const [shrinked, setShrinked] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (Boolean(fieldProps.input.value)) {
      setShrinked(true);
    }
  }, [fieldProps.input.value]);

  const handleFocus = useCallback(() => {
    setShrinked(true);
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    if (!Boolean(fieldProps.input.value)) {
      setShrinked(false);
    }
    setFocused(false);
  }, [fieldProps.input.value]);

  return (
    <label
      className={cn(styles.container, {
        [styles.containerFocused]: Boolean(focused),
      })}
    >
      <span
        className={cn(styles.label, {
          [styles.labelShrinked]: Boolean(shrinked),
        })}
      >
        {label}
      </span>
      <input
        className={cn(
          styles.input,
          {
            [styles.inputWithIcon]: Boolean(icon),
          },
          classNameInput
        )}
        type={type}
        {...rest}
        {...{
          ...fieldProps.input,
          onBlur: handleBlur,
          onFocus: handleFocus,
        }}
        disabled={disabled}
      />
      {icon ? (
        <span className={cn(styles.icon, classNameIcon)}>{icon}</span>
      ) : null}
    </label>
  );
};
