import React, { FC } from "react";

import cn from "classnames";

import { PseudoLinkProps } from "./types";

import styles from "./styles.module.scss";

export const PseudoLink: FC<PseudoLinkProps> = ({
  children,
  className,
  ...restProps
}) => (
  <span className={cn(styles.root, className)} {...restProps}>
    {children}
  </span>
);
