import { ChatDomain } from "./chat-domain";
import { TypingType } from "./chat-types";

export type ChatTypingStore = TypingType;

export const onTypingUpdate = ChatDomain.createEvent<ChatTypingStore>();
export const onTypingReset = ChatDomain.createEvent();

const $chatTypingStore = ChatDomain.createStore<ChatTypingStore>({})
  .on(onTypingUpdate, (_state, typing) => typing)
  .reset(onTypingReset);

export const $chatTyping = $chatTypingStore.map((typing) => typing);
