import React, { ComponentProps, FC } from "react";

type Props = ComponentProps<"svg">;

const ArrowRight: FC<Props> = ({ ...rest }) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 17 16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7825 8.86766L10.7872 8.86302L11.363 8.28716C11.4405 8.20972 11.4801 8.10879 11.4819 8.00731C11.4838 7.90095 11.4442 7.79399 11.363 7.71284L10.7872 7.13698L10.7824 7.13227L6.76904 3.11893C6.61046 2.96036 6.35337 2.96036 6.19479 3.11893L5.61893 3.69479C5.46036 3.85337 5.46036 4.11047 5.61893 4.26904L9.34989 8L5.61893 11.731C5.46036 11.8895 5.46036 12.1466 5.61893 12.3052L6.19479 12.8811C6.35337 13.0396 6.61047 13.0396 6.76904 12.8811L10.7825 8.86766Z"
      fill="#1550A6"
    />
  </svg>
);

export default ArrowRight;
