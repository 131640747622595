import React from "react";

import { ValidationErrorProps } from "./types";

import styles from "./styles.module.scss";

export const ValidationError = ({
  meta: { error, touched },
  showAlways,
}: ValidationErrorProps) => {
  const hasError = Boolean(error && (showAlways || touched));

  if (!hasError) {
    return null;
  }

  return <div className={styles.root}>{error}</div>;
};
