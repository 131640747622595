import React, { FC } from "react";

import { AttachmentIcon } from "./icons/AttachmentIcon";
import { UploadIcon } from "./icons/UploadIcon";
import { PlaceholderProps } from "./types";

import styles from "./styles.module.scss";

export const Placeholder: FC<PlaceholderProps> = ({
  children,
  multiple,
  placeholder,
  optional,
  ...rest
}) => (
  <label className={styles.container} {...rest}>
    <i className={styles.icon}>
      <UploadIcon />
    </i>
    {placeholder ? (
      <div className={styles.text}>{placeholder}</div>
    ) : (
      <div className={styles.text}>
        Drop {multiple ? "files" : "file"} here or select on your сomputer
        {optional ? " (optional)" : ""}
      </div>
    )}
    <i className={styles.iconMobile}>
      <AttachmentIcon />
    </i>
    <div className={styles.textMobile}>
      Select a file{optional ? " (optional)" : ""}
    </div>
    {children}
  </label>
);
