/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useRef, useState } from "react";

import cn from "classnames";
import Downshift from "downshift";

import { ArrowDown16Icon } from "@/components/icons";
import { isEmpty } from "@/lib/validators";

import { SelectProps } from "./types";

import styles from "./styles.module.scss";

export const Select = <T extends { toString(): string } | null>({
  allowEmpty,
  emptyLabel = "",
  options,
  input,
  "data-testid": dataTestid,
  initialInputValue,
  itemToString = (a) => a?.toString() ?? emptyLabel,
  onInputValueChange,
  label,
  disabled = false,
  dropdownBottom = false,
  className,
  readonly = true,
  withArrow = true,
  meta,
  hideError = false,
  getKey: rawGetKey,
  withFilterByValue,
}: SelectProps<T>) => {
  const inputElement = useRef<HTMLInputElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState<"top" | "bottom">(
    "bottom"
  );
  const getLabel = useCallback(
    (item: T | string | null) =>
      item != null && item !== "" ? itemToString(item as T) : emptyLabel,
    [itemToString, emptyLabel]
  );

  const keyFn = useMemo(() => rawGetKey ?? getLabel, [rawGetKey, getLabel]);

  const getKey = useCallback(
    (el: T | null) => (el ? keyFn(el) : getLabel(el)),
    [keyFn, getLabel]
  );

  return (
    <>
      <Downshift<T>
        initialInputValue={initialInputValue ?? getLabel(input.value)}
        itemToString={getLabel}
        onChange={(selection) => {
          input.onChange(
            (selection as any) === emptyLabel ||
              getLabel(selection) === emptyLabel
              ? null
              : selection
          );
        }}
        onInputValueChange={(v, s) => {
          if (onInputValueChange && v) {
            if (v.length === 0) {
              onInputValueChange(null);
            } else if (v.length > 1) {
              onInputValueChange(v);
            }
          }
        }}
        selectedItem={input.value}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          getRootProps,
          selectedItem,
          openMenu,
          getToggleButtonProps,
        }) => {
          const items: ReadonlyArray<T | null> = allowEmpty
            ? ([null] as Array<T | null>).concat(options)
            : options;

          const handleOpen = () => {
            if (dropdownBottom || !inputElement.current) {
              openMenu();
              return;
            }

            const clientHeight = document.documentElement.clientHeight;
            const offsetTop = inputElement.current.getBoundingClientRect().top;

            setDropdownPosition(
              Math.min(items.length * 40 + 20, 280) > clientHeight - offsetTop
                ? "top"
                : "bottom"
            );

            openMenu();
          };

          return (
            <div
              {...getRootProps(undefined, { suppressRefError: true })}
              className={cn(styles.dropdownContainer, className, {
                [styles.error]: meta?.touched && !meta.valid,
                [styles.disabled]: disabled,
                [styles.focus]: isOpen,
              })}
              data-testid={dataTestid}
            >
              <input
                {...getInputProps({ defaultValue: "" })}
                ref={inputElement}
                className={styles.input}
                onBlur={(e) => {
                  input.onBlur(e);
                }}
                onClick={() => !disabled && handleOpen()}
                readOnly={readonly}
              />

              {label && (
                <label
                  className={cn(styles.label, {
                    [styles.shrinked]:
                      !isEmpty(input.value) || !isEmpty(inputValue),
                  })}
                >
                  {label}
                </label>
              )}

              {isOpen && items.length > 0 && (
                <ul
                  {...getMenuProps()}
                  className={cn(styles.dropdownList, {
                    [styles.isOnTop]: dropdownPosition === "top",
                  })}
                >
                  {items
                    .filter(
                      (item) =>
                        !withFilterByValue ||
                        !inputValue ||
                        getLabel(item)
                          .toLocaleLowerCase()
                          .includes(inputValue.toLocaleLowerCase())
                    )
                    .map((item, index) => (
                      <li
                        key={getLabel(item)}
                        className={cn(styles.dropdownItem, {
                          [styles.selected]:
                            inputValue === getLabel(item) ||
                            selectedItem === item,
                        })}
                        {...getItemProps({
                          key: getKey(item),
                          index,
                          item: item ? item : (emptyLabel as any as T),
                        })}
                      >
                        {getLabel(item)}
                      </li>
                    ))}
                </ul>
              )}

              {withArrow && (
                <button
                  type="button"
                  {...getToggleButtonProps()}
                  className={cn(styles.dropdownButton, {
                    [styles.opened]: isOpen,
                  })}
                  disabled={disabled}
                >
                  <ArrowDown16Icon />
                </button>
              )}
            </div>
          );
        }}
      </Downshift>
      {!hideError && meta?.touched && meta.error != null && (
        <div className={styles.errorText}>{meta.error}</div>
      )}
    </>
  );
};
