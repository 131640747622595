// Extracted to separate file to break dependency cycle

import { Document_Api_DocumentTypeEnum } from "@/graphql-schema-types.generated";

// TODO: We should to move these types to some common place, because they are used in many places
export enum FileElementType {
  NEW = "NEW",
  UPLOADING = "UPLOADING",
  UPLOAD_ERROR = "UPLOAD_ERROR",
  JUST_UPLOADED = "JUST_UPLOADED",
  DELETED_OLD = "DELETED_OLD",
  OLD = "OLD",
}
interface DocumentFile {
  type: Document_Api_DocumentTypeEnum;
  code?: string;
}
type Common = {
  document?: DocumentFile;
  description: string;
  name: string;
  size: number | undefined;
};
export type FileElementNew = Common & {
  file: File | Blob;
  fileId: undefined;
  id: undefined;
  type: FileElementType.NEW;
  uuid: string;
};
export type FileElementUploading = Common & {
  file: File | Blob;
  fileId: undefined;
  id: undefined;
  progress: number;
  type: FileElementType.UPLOADING;
  uuid: string;
};
export type FileElementUploadError = Common & {
  error: string;
  file: File | Blob;
  fileId: undefined;
  id: undefined;
  type: FileElementType.UPLOAD_ERROR;
  uuid: string;
};
export type FileElementJustUploaded = Common & {
  file: File | Blob;
  fileId: string;
  id: undefined;
  type: FileElementType.JUST_UPLOADED;
  uuid: string;
};
export type FileElementDeletedOld = Common & {
  fileId: string;
  id: string;
  type: FileElementType.DELETED_OLD;
  uuid: undefined;
};

export type FileElementOld = Common & {
  fileId: string;
  id: string;
  type: FileElementType.OLD;
  uuid: undefined;
};

export type FileElement =
  | FileElementNew
  | FileElementUploading
  | FileElementUploadError
  | FileElementJustUploaded
  | FileElementDeletedOld
  | FileElementOld;
