import { MouseTransition, TouchTransition } from "dnd-multi-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

export const DND_OPTIONS = {
  backends: [
    {
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      backend: TouchBackend,
      options: {
        enableMouseEvents: true,
      },
      preview: true,
      transition: TouchTransition,
    },
  ],
};
