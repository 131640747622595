import { FC, useState } from "react";

import { TabsCMSProps } from "./types";

export const TabsCMS: FC<TabsCMSProps> = ({ children, defaultIndex = 0 }) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);

  return children({
    selectedIndex,
    setSelectedIndex,
  });
};
