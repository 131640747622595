import React, { useCallback } from "react";

import { ImagePreview } from "../ImagePreview";
import { FileInputProps } from "./types";

export const FileInput = ({
  index,
  fileElement,
  editable,
  onClick,
  onDelete,
  onEdit,
}: FileInputProps) => {
  const handleDelete = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);

  const handleClick = useCallback(() => onClick(index), [onClick, index]);

  const handleEdit = useCallback(
    (name: string) => onEdit(index, fileElement, name),
    [onEdit, index, fileElement]
  );

  return (
    <ImagePreview
      editable={editable}
      file={fileElement}
      onClick={handleClick}
      onDelete={handleDelete}
      onEdit={handleEdit}
    />
  );
};
