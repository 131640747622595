import { Account_Api_AccountDetailsEnrichedOutput } from "@/graphql-schema-types.generated";

export default function formatFullName(
  details:
    | Pick<Account_Api_AccountDetailsEnrichedOutput, "first_name" | "last_name">
    | null
    | undefined
) {
  return details != null
    ? [details.first_name, details.last_name].filter(Boolean).join(" ")
    : "";
}
