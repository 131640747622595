import React, { FC } from "react";

import Image from "next/image";

import imageOk from "./mashroom_phone_ok@2x.png";
import imageNeOk from "./mashroom_phone@2x.png";

const PhoneIcon: FC<{ className?: string; isChecked: boolean }> = ({
  isChecked,
  className,
}) => (
  <div className={className}>
    {isChecked ? (
      <Image alt="" src={imageOk} />
    ) : (
      <Image alt="" src={imageNeOk} />
    )}
  </div>
);

export default PhoneIcon;
