import { combine, forward } from "effector";

import { $chatChannels, onChatChannelsReset } from "./channels";
import { ChatDomain } from "./chat-domain";
import { $chatHistory, onChatHistoryReset } from "./chat-history";
import {
  $chatClosedChannelsForAds,
  onClosedChannelsForAdsReset,
} from "./closed-channels-for-ads";
import { $chatError, onChatErrorsReset } from "./errors";
import { $chatInitialized, onInitializedReset } from "./initialized";
import { $chatJoined, onJoinedReset } from "./joined.";
import { $chatMode, onModeReset } from "./mode";
import { $chatPresence, onPresenceReset } from "./presense";
import { $selectedChannel, onSelecteChannelReset } from "./selected-channel";
import { $chatTyping, onTypingReset } from "./typing";
import { $chatUnreadCount, onUnreadCountReset } from "./unread-count";
import {
  $chatUnreadSystemNotificationsCount,
  onUnreadSystemNotificationsCountReset,
} from "./unread-system-notifications-count";

export const onChatReset = ChatDomain.createEvent();

export const $chatStores = combine({
  $chatChannels,
  $chatClosedChannelsForAds,
  $chatError,
  $chatHistory,
  $chatInitialized,
  $chatJoined,
  $chatMode,
  $chatPresence,
  $chatTyping,
  $chatUnreadCount,
  $chatUnreadSystemNotificationsCount,
  $selectedChannel,
});

//reset
forward({
  from: onChatReset,
  to: [
    onChatChannelsReset,
    onChatErrorsReset,
    onChatHistoryReset,
    onClosedChannelsForAdsReset,
    onInitializedReset,
    onJoinedReset,
    onModeReset,
    onPresenceReset,
    onSelecteChannelReset,
    onTypingReset,
    onUnreadCountReset,
    onUnreadSystemNotificationsCountReset,
  ],
});
