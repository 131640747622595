const colors = {
  Accent: "#1550A6",
};

const shadows = {
  input: `0 0 0 4px rgba(254, 245, 239, 0)`,
};

const borders = {
  activeCodeBorder: `3px solid`,
};

const authTheme = {
  colors,
  shadows,
  borders,
};

export default authTheme;
