import React, { forwardRef } from "react";

import cn from "classnames";

import { ButtonProps } from "./types";
import { getHtmlTypeProp } from "./utils/getHtmlTypeProp";

import styles from "./styles.module.scss";

// eslint-disable-next-line import/no-unresolved
import IconLoader16 from "!!@svgr/webpack!./assets/loader16.svg";
// eslint-disable-next-line import/no-unresolved
import IconLoader24 from "!!@svgr/webpack!./assets/loader24.svg";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      htmlType = "button",
      size = "medium",
      variant = "danger",
      block,
      classNameButton,
      classNameIcon,
      classNameText,
      classNameLoader,
      loading,
      iconBefore,
      iconAfter,
      noShadow,
      ...rest
    },
    ref
  ) => (
    <button
      {...rest}
      ref={ref}
      className={cn(
        styles.button,
        {
          [styles.large]: size === "large",
          [styles.xLarge]: size === "x-large",
          [styles.medium]: size === "medium",
          [styles.small]: size === "small",
          [styles.danger]: variant === "danger",
          [styles.dangerOutline]: variant === "dangerOutline",
          [styles.lightYellow]: variant === "lightYellow",
          [styles.borderedOutline]: variant === "borderedOutline",
          [styles.light]: variant === "light",
          [styles.lightGrey]: variant === "lightGrey",
          [styles.minimal]: variant === "minimal",
          [styles.secondary]: variant === "secondary",
          [styles.lightGrey]: variant === "lightGrey",
          [styles.block]: Boolean(block),
          [styles.withShadow]: !noShadow,
        },
        classNameButton
      )}
      type={getHtmlTypeProp(htmlType)}
    >
      {iconBefore != null && (
        <>
          {loading ? (
            <span
              className={cn(styles.loading, styles.iconBefore, classNameLoader)}
            >
              {size === "small" || size === "medium" ? (
                <IconLoader16 />
              ) : (
                <IconLoader24 />
              )}
            </span>
          ) : (
            <span className={cn(styles.iconBefore, classNameIcon)}>
              {iconBefore}
            </span>
          )}
        </>
      )}
      <span className={cn(styles.buttonContent, classNameText)}>
        {children}
      </span>
      {(iconAfter != null || loading) && (
        <>
          {loading ? (
            <span
              className={cn(styles.loading, styles.iconAfter, classNameLoader)}
            >
              {size === "small" || size === "medium" ? (
                <IconLoader16 />
              ) : (
                <IconLoader24 />
              )}
            </span>
          ) : (
            <span className={cn(styles.iconAfter, classNameIcon)}>
              {iconAfter}
            </span>
          )}
        </>
      )}
    </button>
  )
);
