import React, { FC } from "react";

import MultiBackend from "dnd-multi-backend";
import { DndProvider } from "react-dnd";

import { DND_OPTIONS } from "./constants";

export const HtmlAndTouchDndProvider: FC = ({ children }) => (
  <DndProvider backend={MultiBackend} options={DND_OPTIONS}>
    {children}
  </DndProvider>
);
