import { UnreachableCaseError } from "ts-essentials";

import { getMediaUrl } from "@/lib/utils";
import { FileElement, FileElementType } from "@/uikit2/types";

export const formatImageSrc = (fileElement: FileElement): string => {
  switch (fileElement.type) {
    case FileElementType.DELETED_OLD:
    case FileElementType.UPLOADING:
    case FileElementType.NEW:
    case FileElementType.UPLOAD_ERROR:
      return ""; //TODO Implement loading from file.
    case FileElementType.JUST_UPLOADED:
    case FileElementType.OLD:
      return getMediaUrl(fileElement.fileId);
    default:
      throw new UnreachableCaseError(fileElement);
  }
};
