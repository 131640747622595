import React from "react";

export const AttachmentIcon = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.03516 11.4652L11.9294 4.57089C13.9797 2.52064 17.3038 2.52064 19.3541 4.57089C21.4043 6.62115 21.4043 9.94526 19.3541 11.9955L11.3991 19.9505C10.0323 21.3173 7.8162 21.3173 6.44937 19.9505C5.08253 18.5836 5.08253 16.3676 6.44937 15.0007L14.4043 7.04577C15.0877 6.36235 16.1958 6.36235 16.8792 7.04577C17.5626 7.72918 17.5626 8.83722 16.8792 9.52064L9.9849 16.4149"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
