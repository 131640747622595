import { makePropertyType } from "@/features/public/search/lib";

import { Property } from "../types/property";

export const makePropertyLink = (property: Property) =>
  `/property/${makePropertyType(
    false,
    property.details.type,
    property.details.bedrooms_no ?? 0
  )}-${property.id}`;
