import { ChatDomain } from "./chat-domain";

export type ChatUnreadSystemNotificationsCountStore = number;

export const onChatUnreadSystemNotificationsCountChanged =
  ChatDomain.createEvent<ChatUnreadSystemNotificationsCountStore>();
export const onUnreadSystemNotificationsCountReset = ChatDomain.createEvent();

const $chatChatUnreadSystemNotificationsCountStore =
  ChatDomain.createStore<ChatUnreadSystemNotificationsCountStore>(0)
    .on(
      onChatUnreadSystemNotificationsCountChanged,
      (_state, unreadSystemNotificationsCount) => unreadSystemNotificationsCount
    )
    .reset(onUnreadSystemNotificationsCountReset);

export const $chatUnreadSystemNotificationsCount =
  $chatChatUnreadSystemNotificationsCountStore.map(
    (unreadSystemNotificationsCount) => unreadSystemNotificationsCount
  );
