import { ChatDomain } from "./chat-domain";

export type ChatJoinedStore = boolean;

export const onChatJoined = ChatDomain.createEvent();
export const onJoinedReset = ChatDomain.createEvent();

const $chatJoinedStore = ChatDomain.createStore<ChatJoinedStore>(false)
  .on(onChatJoined, () => true)
  .reset(onJoinedReset);

export const $chatJoined = $chatJoinedStore.map((joined) => joined);
