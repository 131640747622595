import { ChatDomain } from "./chat-domain";

export type ChatUnreadCountStore = number;
export type ChatUnreadCountStore2 = {
  [channelId: string]: number;
};

export const onChatUnreadCountChanged =
  ChatDomain.createEvent<ChatUnreadCountStore>();
export const onUnreadCountReset = ChatDomain.createEvent();

const $chatChatUnreadCountStore = ChatDomain.createStore<ChatUnreadCountStore>(
  0
)
  .on(onChatUnreadCountChanged, (_state, unreadCount) => unreadCount)
  .reset(onUnreadCountReset);

export const $chatUnreadCount = $chatChatUnreadCountStore.map(
  (unreadCount) => unreadCount
);
