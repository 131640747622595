import React, { memo } from "react";

import Image from "next/image";

import { ImageSize, WordpressImageProps } from "./types";

export const WordpressImage = memo(
  ({
    images,
    lazy,
    layout = "fill",
    objectFit,
    width,
    height,
    ...rest
  }: WordpressImageProps) => {
    let image = images?.full ?? images?.large;

    if (width != null && height != null) {
      const byHeights = (
        Object.entries(images ?? {}) as Array<[string, ImageSize]>
      ).sort((a, b) => (a[1].height ?? 0) - (b[1].height ?? 0));
      const byWidths = (
        Object.entries(images ?? {}) as Array<[string, ImageSize]>
      ).sort((a, b) => (a[1].width ?? 0) - (b[1].width ?? 0));

      const closestSizeByHeight = byHeights.find(
        ([a, b]) => (b.height ?? 0) >= height
      );
      const closestSizeByWidth = byWidths.find(
        ([a, b]) => (b.width ?? 0) >= width
      );

      const imageByH: ImageSize | undefined = closestSizeByHeight
        ? images?.[closestSizeByHeight[0]]
        : undefined;
      const imageByW: ImageSize | undefined = closestSizeByWidth
        ? images?.[closestSizeByWidth[0]]
        : undefined;

      image =
        (imageByH?.width ?? 0) > (imageByW?.width ?? 0) ? imageByH : imageByW;
    }

    return image?.source_url ? (
      <Image
        {...rest}
        height={layout === "fill" ? undefined : height}
        layout={layout}
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        objectFit={layout != null ? objectFit : "cover"}
        src={image.source_url}
        width={layout === "fill" ? undefined : width}
      />
    ) : null;
  }
);
