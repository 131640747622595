import React, { ComponentProps, FC, memo, SVGAttributes } from "react";

import cn from "classnames";

import { DEFAULT_SIZE } from "./constants";
import { parseSizeInPx } from "./utils/parseSizeInPx";

import styles from "./styles.module.scss";

import * as icons from "./svg";

export type IconName = keyof typeof icons;

type IconProps = {
  fill?: string;
  height?: string;
  name: IconName;
  stroke?: string;
  width?: string;
} & ComponentProps<"span">;

type IconSvgProps = {
  name: IconName;
} & SVGAttributes<SVGElement>;

// TODO: When we move all icons to new Icon component we will delete this component
const IconSvg: FC<IconSvgProps> = ({ name, ...restProps }) => {
  const SvgIconByName = icons[name];
  if (SvgIconByName) {
    return <SvgIconByName {...restProps} />;
  }

  // That's strange, I'll just leave it as it is
  const SvgIconByDefault = icons[0];
  if (SvgIconByDefault) {
    return <SvgIconByDefault {...restProps} />;
  }

  return null;
};

export const Icon: FC<IconProps> = memo(
  ({
    fill,
    height: heightProp,
    name,
    stroke,
    width: widthProp,
    ...restProps
  }) => {
    const height = parseSizeInPx(heightProp, DEFAULT_SIZE);
    const width = parseSizeInPx(widthProp, DEFAULT_SIZE);

    const style = {
      "--icon-height": `${height}px`,
      "--icon-width": `${width}px`,
      "--icon-fill": fill,
      "--icon-stroke": stroke,
      ...restProps.style,
    } as any;

    return (
      <span
        {...restProps}
        className={cn(styles.root, {
          [styles["root--withFill"]]: fill,
          [styles["root--withStroke"]]: stroke,
        })}
        style={style}
      >
        <IconSvg
          height={width}
          name={name}
          viewBox={`0 0 ${width} ${height}`}
          width={width}
        />
      </span>
    );
  }
);
