import React, { useCallback, useMemo, useState } from "react";

import classNames from "classnames";
import { FORM_ERROR } from "final-form";
import { Field, Form } from "react-final-form";
import PhoneInput from "react-phone-input-2";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import { Close24Icon } from "@/components/icons";
import { useRefetchAuthInfoEvent } from "@/core/Auth/effector";
import { useHasVerifiedPhone, useUserInfo } from "@/core/Auth/hooks";
import Button from "@/uikit2/atoms/Button";
import { ApiError } from "@/uikit2/components/ApiError";

import CodeVerifyModal from "./CodeVerifyModal";
import PhoneIcon from "./CodeVerifyModal/components/PhoneIcon";
import { useCheckCodeMutation } from "./graphql/mutations/CheckCode.generated";
import { PhoneVerificationSkeleton } from "./PhoneVerificationSkeleton/phone-verificatoin-skeleton";

import "react-phone-input-2/lib/style.css";
import css from "./index.module.css";

const cn = classNames.bind(css);

type FormValues = {
  phone: string | undefined;
};

/**
 * @deprecated  use src/uikit2/components/PhoneInput
 */

const PhoneVerification = () => {
  const auth = useUserInfo();
  const refetchAuthInfo = useRefetchAuthInfoEvent();
  const phoneFromAuth = auth?.contact?.phone_primary ?? "";
  const isVerified = useHasVerifiedPhone();
  const [isModalOpen, setModalOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [phone, setPhone] = useState<string>("");
  const [checkCode, { error: checkError }] = useCheckCodeMutation();

  const handleSubmit = useCallback((values: FormValues) => {
    if (values.phone == null || values.phone.length < 11) {
      return { [FORM_ERROR]: "Enter full phone number" };
    }
    setPhone(values.phone);
    setModalOpen(true);
    return undefined;
  }, []);

  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleModalSubmit = useCallback(
    async (values: { phonecode: string }) => {
      const response = await checkCode({
        variables: {
          code: values.phonecode,
          phone,
        },
      });
      const success = response.data?.auth?.verify?.phone?.success ?? false;
      if (success) {
        refetchAuthInfo();
        setShowSuccess(true);
        return undefined;
      } else {
        return { [FORM_ERROR]: "Incorrect code" };
      }
    },
    [checkCode, phone, refetchAuthInfo]
  );

  const classNames = useMemo(
    () => ({
      enter: css.textEnter,
      enterActive: css.textEnterActive,
    }),
    []
  );

  const initialValues = useMemo(
    () => ({ phone: phoneFromAuth }),
    [phoneFromAuth]
  );

  const preferredCountries = useMemo(() => ["gb"], []);
  const errors = useMemo(() => [checkError], [checkError]);

  const showChecked = isVerified && !isModalOpen && showSuccess;

  if (isVerified && !showSuccess) {
    return null;
  }

  return (
    <div className={cn(css.mainContainer, { [css.verified]: showChecked })}>
      <div className={css.message}>
        <SwitchTransition>
          <CSSTransition
            key={showChecked ? "verified" : "notVerified"}
            classNames={classNames}
            exit={false}
            timeout={300}
          >
            <span className={css.text}>
              {showChecked ? (
                <>
                  <div>Verification successful!</div>
                  <div className={css.subText}>
                    You will now receive notifications instantly via SMS
                  </div>
                </>
              ) : (phoneFromAuth !== "" ? (
                <>
                  Verify your phone number to&nbsp;receive important
                  notifications
                </>
              ) : (
                <>
                  Add your phone number to&nbsp;receive notifications instantly
                </>
              ))}
            </span>
          </CSSTransition>
        </SwitchTransition>
      </div>

      {showChecked && (
        <button
          className={css.closeButton}
          onClick={() => setShowSuccess(false)}
          title="close"
          type="button"
        >
          <Close24Icon />
        </button>
      )}

      <div className={css.formAndImage}>
        <div>
          <PhoneIcon className={css.image} isChecked={showChecked} />
        </div>

        {!isVerified && (
          <Form<FormValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, submitError }) => (
              <form className={css.form} onSubmit={handleSubmit}>
                <Field name="phone" type="tel">
                  {({ input, meta }) => (
                    <div className={cn(css.phoneInputWrapper)}>
                      <PhoneInput
                        {...input}
                        country="gb"
                        countryCodeEditable={false}
                        inputClass={css.phoneInput}
                        placeholder="Enter your phone number"
                        preferredCountries={preferredCountries}
                      />
                      {submitError && (
                        <div className={cn(css.error)}>{submitError}</div>
                      )}
                    </div>
                  )}
                </Field>

                <Button.Blue className={css.getCodeButton} type="submit">
                  Get code
                </Button.Blue>
              </form>
            )}
          </Form>
        )}
      </div>

      {checkError && <ApiError errors={errors} />}

      <CodeVerifyModal
        isCodeCorrect={isVerified}
        isModalOpen={isModalOpen}
        onClose={handleClose}
        onSubmit={handleModalSubmit}
        phone={phone}
      />
    </div>
  );
};

const PhoneVerificationWrapper = () => {
  const currentUser = useUserInfo();

  if (!currentUser?.id) {
    return <PhoneVerificationSkeleton />;
  }

  return <PhoneVerification />;
};

export { PhoneVerificationWrapper as PhoneVerification };
