import React, { CSSProperties, FC } from "react";

import cn from "classnames";

import css from "./index.module.css";

type Props = {
  animation?: "wave" | "fade";
  variant?: "round" | "rect";
  width?: number | string;
  height?: number | string;
  className?: string;
  style?: CSSProperties;
};

export const Skeleton: FC<Props> = ({
  variant = "rect",
  animation = "fade",
  width,
  height,
  className,
  style,
  children,
  ...rest
}) => (
  <div
    {...rest}
    className={cn(
      css[variant],
      {
        [css.fade]: animation === "fade",
        [css.wave]: animation === "wave",
      },
      className
    )}
    style={{
      width,
      height,
      ...style,
    }}
  >
    &nbsp;
  </div>
);
