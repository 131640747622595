import React, { FC } from "react";

import Link from "next/link";

import { Property } from "../../types/property";
import { makePropertyLink } from "../../utils";

export const PropertyLink: FC<{ property: Property; preview?: boolean }> = ({
  property,
  preview,
  ...rest
}) =>
  preview ? (
    <Link
      {...rest}
      as={`/property-preview/${property.id}`}
      href="/property-preview/[slug]"
      passHref
    />
  ) : (
    <Link
      {...rest}
      as={makePropertyLink(property)}
      href="/property/[slug]"
      passHref
    />
  );
