import React, { useCallback, useState } from "react";

import { required } from "@dmitry.olyenyov/final-form-validations";
import cn from "classnames";
import { FORM_ERROR } from "final-form";
import { Field, Form } from "react-final-form";

import { Modal } from "@/components/ui";
import { RecaptchaEnterprise } from "@/entities/RecaptchaEnterprise/recaptchaEnterprise";
import Button from "@/uikit2/atoms/Button";

import { useSendCodeMutation } from "../graphql/mutations/SendCode.generated";
import FormCode from "./components/FormCode/FormCode";

import css from "./index.module.css";

type otpValuesType = {
  phonecode: string;
};

type FormValues = {
  recaptcha_token: string | undefined;
};

interface Props {
  onClose: () => void;
  onSubmit: (
    values: otpValuesType
  ) => Promise<{ [FORM_ERROR]: string } | undefined>;
  phone: string;
  isCodeCorrect: boolean;
  isModalOpen: boolean;
}

export default function CodeVerifyModal({
  isModalOpen,
  onClose,
  phone,
  onSubmit,
  isCodeCorrect,
}: Props) {
  const [showFormCode, setVisibilityFormCode] = useState<boolean>(false);
  const [sendCode, { error: sendError, loading: sendLoading }] =
    useSendCodeMutation();

  const handleHideFormCode = useCallback(() => {
    setVisibilityFormCode(false);
  }, []);

  const handleSendCode = useCallback(
    async (phone: string, recaptcha_token: string) => {
      await sendCode({
        variables: {
          phone,
          recaptcha_token,
        },
      }).then(() => {
        setVisibilityFormCode(true);
      });
    },
    [sendCode]
  );

  const handleResend = useCallback(
    ({ recaptcha_token }: FormValues) => {
      if (recaptcha_token) {
        return handleSendCode(phone, recaptcha_token);
      }

      return undefined;
    },
    [handleSendCode, phone]
  );

  const handleClose = useCallback(() => {
    onClose();
    handleHideFormCode();
  }, [handleHideFormCode, onClose]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleClose}
      size="md"
      title={isCodeCorrect ? "Success!" : "Security code"}
    >
      {showFormCode ? (
        <FormCode
          isCodeCorrect={isCodeCorrect}
          isModalOpen={isModalOpen}
          onClose={handleClose}
          onResend={handleHideFormCode}
          onSubmit={onSubmit}
          phone={phone}
        />
      ) : (
        <>
          <p className={cn(css.subHeader)}>Your should verify yourself.</p>

          <Form onSubmit={handleResend}>
            {({ handleSubmit, hasValidationErrors }) => (
              <form onSubmit={handleSubmit}>
                <div className={css.captcha}>
                  <Field
                    component={RecaptchaEnterprise}
                    name="recaptcha_token"
                    validate={required()}
                  />
                </div>
                {Boolean(sendError) && (
                  <div className={css.errorMessage}>{sendError?.message}</div>
                )}
                <div>
                  <Button.Blue
                    disabled={hasValidationErrors || sendLoading}
                    type="submit"
                  >
                    Get code
                  </Button.Blue>
                </div>
              </form>
            )}
          </Form>
        </>
      )}
    </Modal>
  );
}
