import { ChatDomain } from "./chat-domain";

export type ChatClosedChannelsForAdsStore = string[];

export const onChatClosedChannelsForAdsChanged =
  ChatDomain.createEvent<string>();
export const onClosedChannelsForAdsReset = ChatDomain.createEvent();

const $chatChatClosedChannelsForAdStore =
  ChatDomain.createStore<ChatClosedChannelsForAdsStore>([])
    .on(onChatClosedChannelsForAdsChanged, (state, channel) => [
      ...state,
      channel,
    ])
    .reset(onClosedChannelsForAdsReset);

export const $chatClosedChannelsForAds = $chatChatClosedChannelsForAdStore.map(
  (channels) => channels
);
