import React, { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { FORM_ERROR } from "final-form";
import { Field, Form } from "react-final-form";
import PinInput from "react-pin-input";
import { useMediaQuery } from "react-responsive";

import authTheme from "@/features/auth/utils/authTheme";
import Button from "@/uikit2/atoms/Button";

import { FullContentSpinner } from "../../../../FullContentSpinner";

import css from "./index.module.css";

const cn = classNames.bind(css);

type otpValuesType = {
  phonecode: string;
};

interface Props {
  onResend: () => void;
  onClose: () => void;
  onSubmit: (
    values: otpValuesType
  ) => Promise<{ [FORM_ERROR]: string } | undefined>;
  phone: string;
  isCodeCorrect: boolean;
  isModalOpen: boolean;
}

export default function FormCode({
  isModalOpen,
  onClose,
  onResend,
  phone,
  onSubmit,
  isCodeCorrect,
}: Props) {
  const [seconds, setSeconds] = useState(60);
  const small = useMediaQuery({ maxWidth: 867 });

  useEffect(() => {
    if (!seconds || !isModalOpen) {
      return;
    }

    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [isModalOpen, seconds]);

  const handleResend = () => {
    setSeconds(60);
    onResend();
  };

  const inputFocusStyle = useCallback(
    (hasSubmitError: boolean) => ({
      border: authTheme.borders.activeCodeBorder,
      borderColor: hasSubmitError ? "#E23838" : authTheme.colors.Accent,
    }),
    []
  );

  const inputStyle = useCallback(
    (hasSubmitError: boolean) => ({
      width: !small ? "52px" : "40px",
      height: !small ? "60px" : "48px",
      margin: 0,
      boxShadow: authTheme.shadows.input,
      border: authTheme.borders.activeCodeBorder,
      borderColor: hasSubmitError ? "#E23838" : "#F0B4AA",
      borderRadius: "10px",
      transition: "all 0.2s",
    }),
    [small]
  );

  const style = useMemo(
    () => ({
      padding: 0,
      display: "grid",
      gridTemplateColumns: `repeat(5, ${small ? 38 : 52}px)`,
      columnGap: "35px",
      justifyContent: "center",
    }),
    [small]
  );

  return (
    <>
      <p className={cn(css.subHeader)}>
        {isCodeCorrect
          ? `Number +${phone} has been successfully verified!`
          : `We sent a security code on this number: +${phone}. It may take a\n` +
            "minute to arrive."}
      </p>
      {isCodeCorrect ? (
        <Button.Blue
          className={cn(css.backButton)}
          onClick={onClose}
          type="button"
        >
          Back to profile
        </Button.Blue>
      ) : (
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form, submitting, submitError }) => (
            <form className={cn(css.formLayout)} onSubmit={handleSubmit}>
              {submitting ? (
                <FullContentSpinner />
              ) : (
                <Field name="phonecode">
                  {({ input, meta }): JSX.Element => (
                    <PinInput
                      initialValue=""
                      inputFocusStyle={inputFocusStyle(Boolean(submitError))}
                      {...input}
                      focus
                      inputStyle={inputStyle(Boolean(submitError))}
                      length={5}
                      onComplete={() => form.submit()}
                      style={style}
                      type="numeric"
                    />
                  )}
                </Field>
              )}

              {submitError && (
                <div className={cn(css.error)}>{submitError}</div>
              )}
              <div className={cn(css.timer)}>
                {!seconds ? (
                  <p>
                    Resend&nbsp;<span onClick={handleResend}>code</span>
                  </p>
                ) : (
                  <p>
                    Resend code in&nbsp;<span> {seconds} seconds </span>{" "}
                  </p>
                )}
              </div>
            </form>
          )}
        </Form>
      )}
    </>
  );
}
