import { ChatDomain } from "./chat-domain";
import { ChatInitializedStore } from "./chat-types";

export const onInitializedChanged =
  ChatDomain.createEvent<ChatInitializedStore>();
export const onInitializedReset = ChatDomain.createEvent();

const $ChatInitializedStore = ChatDomain.createStore<ChatInitializedStore>(
  ChatInitializedStore.NONE
)
  .on(onInitializedChanged, (_state, payload) => payload)
  .reset(onInitializedReset);

export const $chatInitialized = $ChatInitializedStore.map((v) => v);
