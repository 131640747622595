import React, { FC } from "react";

import Link from "next/link";

export const PropertyPreviewLink: FC<{ propertyId: string }> = ({
  propertyId,
  ...rest
}) => (
  <Link
    {...rest}
    as={`/property-preview/${propertyId}`}
    href="/property-preview/[slug]"
    passHref
  />
);
